import gql from "graphql-tag";

const visibleTasks = `
  {
    id
    title
    kenticoCodename
    taskCompletionsCount
    taskType
    pointsAwarded
    progress {
      taskId
      teamId
      status
      taskCompletionsCount
    }
    ... on PledgeTask {
      pointsOnCompletion
    }
    ... on ReadTask {
      pointsOnCompletion
    }
    ... on WatchTask {
      pointsOnCompletion
    }
    ... on DonorboxTask {
      donationsTotal
      donorboxPointsMultiplier
    }
    ... on DonationTask {
      donationPointsMultiplier
      beneficiary {
        id
        name
        website
        address
      }
    }
  }
  nextToken
`;
export const GET_ALL_USERS_QUERY = gql`
  fragment UserFragment on User {
    name: String
    age: Int
  }

  query getAllUsers {
    getAllUsers {
      ...UserFragment
    }
  }
`;

export const GET_MY_CONTACT_PREFERENCES = gql`
  query getMyContactPreferences {
    getMyProfile {
      contactPreferences
    }
  }
`;

export const GET_PROFILE = gql`
  query getMyProfile {
    getMyProfile {
      ...myProfileFields
    }
  }
  fragment myProfileFields on MyProfile {
    backgroundImageUrl
    bio
    birthdate
    displayName
    email
    id
    profileImageUrl
    screenName
    pointsBalance
    pointsAwarded
    teamCaptaincies {
      teams {
        slug
        id
        name
        imageUrl
      }
    }
    teamMemberships {
      teams {
        imageUrl
        name
        slug
        id
        kenticoCodename
      }
      nextToken
    }
    posts {
      nextToken
      posts {
        id
        creator {
          id
          displayName
          screenName
          profileImageUrl
        }
        publishState
        text
        textColor
        backgroundColor
        imageUrl
        videoUrl
        textPlacement {
          angle
          x
          y
        }
      }
    }
    tasks {
      tasks {
        id
        coverImageUrl
        coverHeading
        coverContent
        kenticoCodename
        team {
          name
          slug
        }
        teamId
      }
      nextToken
    }
    verified
    followersCount
    followingCount
    teamMembershipsCount
  }
`;

export const GET_OTHER_PROFILE = gql`
  query getProfile($screenName: String!) {
    getProfile(screenName: $screenName) {
      ...otherProfileFields
    }
  }
  fragment otherProfileFields on OtherProfile {
    backgroundImageUrl
    bio
    displayName
    id
    profileImageUrl
    screenName
    pointsBalance
    pointsAwarded
    teamMemberships {
      teams {
        imageUrl
        name
        slug
        id
        kenticoCodename
        teamCaptains {
          profiles {
            displayName
            profileImageUrl
            id
            screenName
          }
        }
      }
      nextToken
    }
    teamCaptaincies {
      teams {
        slug
        id
        name
        imageUrl
      }
    }
    posts {
      nextToken
      posts {
        id
        creator {
          id
          displayName
          screenName
          profileImageUrl
        }
        publishState
        text
        textColor
        backgroundColor
        imageUrl
        videoUrl
        textPlacement {
          angle
          x
          y
        }
      }
    }
    tasks {
      tasks {
        id
        coverImageUrl
        coverHeading
        coverContent
        kenticoCodename
        team {
          name
          slug
        }
        teamId
      }
      nextToken
    }
    following
    followersCount
    followingCount
    verified
    teamMembershipsCount
  }
`;

export const GET_EDIT_PROFILE_FIELDS = gql`
  query getProfile($screenName: String, $profileId: ID) {
    getProfile(screenName: $screenName, profileId: $profileId) {
      id
      displayName
      screenName
      bio
      profileImageUrl
      backgroundImageUrl
    }
  }
`;

export const GET_TEAMS_QUERY = gql`
  query getTeams($nextToken: String) {
    getTeams(limit: 10, nextToken: $nextToken) {
      teams {
        id
        name
        slug
        kenticoCodename
        teamCaptains {
          profiles {
            profileImageUrl
            screenName
            id
            displayName
          }
        }
      }
      nextToken
    }
  }
`;
export const GET_TEAM_TASKS = gql`
  query getVisibleTeamTasks($limit: Int!, $teamId: ID!, $nextToken: String) {
    getVisibleTeamTasks(limit: $limit, teamId: $teamId, nextToken: $nextToken) {
      tasks ${visibleTasks}
    }
  }
`;

export const GET_TEAM_QUERY = gql`
  query getTeam($slug: String, $id: String) {
    getTeam(slug: $slug, id: $id) {
      slug
      name
      id
      member
      membersCount
      pointsAwarded
      donationsTotal
      kenticoCodename
      members {
        profiles {
          id
          profileImageUrl
          screenName
          displayName
        }
      }
      teamCaptains {
        profiles {
          id
          displayName
          profileImageUrl
          screenName
        }
      }
      visibleTasks(limit: 50) {
        tasks ${visibleTasks}
      }
      posts {
        nextToken
        posts {
          id
          backgroundColor
          text
          textColor
          videoUrl
          imageUrl
          publishState
          creator {
            displayName
            profileImageUrl
            id
            screenName
          }
          textPlacement {
            angle
            x
            y
          }
        }
      }
    }
  }
`;

export const GET_TEAM_ID = gql`
  query getTeam($slug: String, $id: String) {
    getTeam(slug: $slug, id: $id) {
      id
      kenticoCodename
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query getTeamMembers($teamId: ID!, $limit: Int!, $nextToken: String) {
    getTeamMembers(limit: $limit, teamId: $teamId, nextToken: $nextToken) {
      profiles {
        id
        displayName
        screenName
        profileImageUrl
        pointsAwarded
      }
      nextToken
    }
  }
`;

export const GET_PAYMENT_INTENT = gql`
  query getPaymentIntent($id: ID!) {
    getPaymentIntent(id: $id) {
      id
      status
    }
  }
`;

export const GET_PROFILE_FOLLOWERS = gql`
  query getProfileFollowers($limit: Int!, $profileId: ID!, $nextToken: String) {
    getProfileFollowers(
      limit: $limit
      profileId: $profileId
      nextToken: $nextToken
    ) {
      profiles {
        id
        displayName
        screenName
        profileImageUrl
        ... on OtherProfile {
          following
        }
        ... on MyProfile {
          id
          email
        }
      }
      nextToken
    }
  }
`;

export const GET_PROFILE_FOLLOWING = gql`
  query getProfileFollowing($limit: Int!, $profileId: ID!, $nextToken: String) {
    getProfileFollowing(
      limit: $limit
      profileId: $profileId
      nextToken: $nextToken
    ) {
      profiles {
        id
        displayName
        screenName
        profileImageUrl
        ... on OtherProfile {
          following
        }
        ... on MyProfile {
          id
          email
        }
      }
      nextToken
    }
  }
`;

export const GET_PROFILE_SUPPORT_FORM = gql`
  query getProfileSupportForm {
    getMyProfile {
      email
      displayName
    }
  }
`;

export const GET_PROFILE_FIELDS_FOR_DONATE_BILLING_DETAILS = gql`
  query getProfileFieldsForDonateBillingDetails {
    getMyProfile {
      email
      displayName
    }
  }
`;

export const MODERATOR_GET_PROFILE = gql`
  query getProfile($profileId: ID!) {
    getProfile(profileId: $profileId) {
      id
      displayName
      cognitoUser {
        Enabled
        UserStatus
      }
    }
  }
`;

export const GET_PROFILE_BY_NEWSLETTER_UNSUBSCRIBE_ID = gql`
  query getProfileByNewsletterUnsubscribeId($newsletterUnsubscribeId: String!) {
    getProfileByNewsletterUnsubscribeId(
      newsletterUnsubscribeId: $newsletterUnsubscribeId
    ) {
      id
      contactPreferences
    }
  }
`;
