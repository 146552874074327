import gql from "graphql-tag";

export const GET_TASK = gql`
  query getTask($id: ID!) {
    getTask(id: $id) {
      id
      teamId
      taskType
      title
      kenticoCodename
      progress {
        status
      }
      team {
        id
        name
        member
        slug
      }
      ... on PledgeTask {
        pointsOnCompletion
      }
      ... on ReadTask {
        pointsOnCompletion
      }
      ... on DonorboxTask {
        donationsTotal
        donorboxPointsMultiplier
      }
      ... on WatchTask {
        pointsOnCompletion
      }
      ... on DonationTask {
        donationPointsMultiplier
        beneficiary {
          id
          name
          website
          address
        }
      }
    }
  }
`;
